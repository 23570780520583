<template>
  <el-col class="page">
      <el-col class="title">退订列表</el-col>
      <el-col class="pane-container">
        //'0:未付款,1:已付款,2:已发货,3:已签收,5取消交易,6退货申请,7退货审核中，8审核通过(退货中)，9审核不通过(不同意退货)，-5已退货,-1 退款失败'
        <el-col  :class="{ 'pane-active':active==6 }" class="pane cursor-item" @click.native="paneActive('6')">退货申请</el-col>
        <el-col  :class="{ 'pane-active':active==7 }" class="pane cursor-item" @click.native="paneActive('7')">退货审核中</el-col>
        <el-col  :class="{ 'pane-active':active==8 }" class="pane cursor-item" @click.native="paneActive('8')">审核通过</el-col>
        <el-col  :class="{ 'pane-active':active==9 }" class="pane cursor-item" @click.native="paneActive('9')">审核不通过</el-col>
        <el-col  :class="{ 'pane-active':active==-5 }" class="pane cursor-item" @click.native="paneActive('-5')">已退货</el-col>
      </el-col>
    <el-col class="table-container">
      <el-table
          v-loading="loading"
          :data="tableData"
          border
          style="width: 100%">
        <el-table-column
            prop="orderNo"
            label="订单号">

          <template slot-scope="scope">
            <el-tooltip :content="scope.row.orderNo">
              <div slot>{{scope.row.orderNo}}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
            prop="status"
            :formatter ="statusFormatter"
            label="订单状态">
        </el-table-column>
        <el-table-column
            prop="number"
            label="数量">
        </el-table-column>
        <el-table-column
            prop="returnSupport"
            :formatter ="returnSupportFormatter"
            label="支持退货">
        </el-table-column>
        <el-table-column
            prop="price"
            label="订单价格">
        </el-table-column>
        <el-table-column
            prop="payChannel"
            label="支付渠道">
        </el-table-column>
        <el-table-column
            prop="payNo"
            label="支付单号">
        </el-table-column>
        <el-table-column
            prop="paymentTime"
            label="付款时间">
        </el-table-column>
        <el-table-column
            prop="isInvoice"
            :formatter ="isInvoiceFormatter"
            label="是否开票">
        </el-table-column>
        <el-table-column
            prop="isContract"
            :formatter ="isContractFormatter"
            label="申请合同">
        </el-table-column>
        <el-table-column
            prop="isComment"
            :formatter ="isCommentFormatter"
            label="是否评论">
        </el-table-column>
      </el-table>
    </el-col>
    <el-col style="margin-top: 20px">
      <Page :total="page.total"  :current="page.pageNo" :page-size="page.pageSize"
            @on-page-size-change="onPageSizeChange" @on-change="changePage" show-total show-elevator style="font-size: 14px"></Page>
    </el-col>
  </el-col>
</template>

<script>
import iView from 'iview'    //引入ivew库
import 'iview/dist/styles/iview.css'    // 使用 CSS
import Vue from 'vue'
import unsubscribe from "../../../api/buyer/transaction/Unsubscribe";
Vue.use(iView)
export default {
  name: "Unsubscribe",
  data() {
    return {
      loading: true,
      active: 1,
      tableData: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 3
      },
    }
  },
  methods: {
    onPageSizeChange(index) {
      this.page.pageSize = index;
      this.getOptOut(this.active)
    },
    changePage(index) {
      this.page.pageNo = index;
      this.getOptOut(this.active)
    },
    paneActive(id) {
      this.active = id;
      this.getOptOut(id)
    },
    getOptOut(status){
      this.tableData = [];
      //'0:未付款,1:已付款,2:已发货,3:已签收,5取消交易,6退货申请,7退货审核中，8审核通过(退货中)，9审核不通过(不同意退货)，-5已退货,-1 退款失败'
      unsubscribe.getOptOut({pageNo: this.page.pageNo, pageSize: this.page.pageSize,status:status}).then((res) => {
        //console.log("321")
        //console.log(res)
        this.timer = setTimeout(()=>{   //设置延迟执行
          this.loading = false
        },300)
        this.page.total = 0;
        if(res.data) {
          //this.tableData = res.data.records;
          this.tableData = res.data.records
          this.page.total = res.data.total
        }
      })
    },
    returnSupportFormatter(row){
      //0：支持，1:不支持
      switch (row.returnSupport){
        case 0:
          return '支持';
        case 1:
          return '不支持';
        default:
          return '不支持';
      }
    },
    isInvoiceFormatter(row){
      switch (row.isInvoice){
        case 0:
          return '未开票';
        case 1:
          return '已开票';
        default:
          return '已取消';
      }
    },
    isContractFormatter(row){
      switch (row.isContract){
        case 0:
          return '未申请';
        case 1:
          return '已申请';
        case 2:
          return '已取消';
        default:
          return '未申请';
      }
    },
    isCommentFormatter(row){
      //是否评论 0：未评论 1：已评论
      switch (row.isComment){
        case 0:
          return '未评论';
        case 1:
          return '已评论';
        default:
          return '未评论';
      }
    },
    statusFormatter(row){
      //'0:未付款,1:已付款,2:已发货,3:已签收,5取消交易,6退货申请,7退货审核中，8审核通过(退货中)，9审核不通过(不同意退货)，-5已退货,-1 退款失败'
      switch (row.isComment){
        case 0:
          return '未付款';
        case 1:
          return '已付款';
        case 2:
          return '已发货';
        case 3:
          return '已签收';
        case 5:
          return '取消交易';
        case 6:
          return '退货申请';
        case 7:
          return '退货审核中';
        case 8:
          return '审核通过';
        case 9:
          return '审核不通过';
        case -5:
          return '已退货';
        case -1:
          return '退款失败';
        default:
          return '未付款';
      }
    }

  },
  mounted() {
    this.paneActive(6)
  },
}
</script>

<style lang="less" scoped>
.cursor-item {
    cursor: pointer;
}
.page {
  margin: 20px 35px;
  width: 97%;
  .title {
    color: #F56E65;
    font-size: 24px;
    letter-spacing: 1px;
    margin-bottom: 40px;
  }
  .pane-container {
    height: 34px;
    .pane {
      width:100px;
      height: 16px;
      font-size: 16px;
      margin-right: 40px;
      color: rgba(65, 65, 65, 1);
    }
    .pane:active {
      color: #F56E65;
    }
    .pane:hover {
      color: #F56E65;
    }
    .pane-active {
      color: #F56E65;
    }

  }
  .table-container {
    margin-top: 20px;
   /deep/.el-table .cell, .el-table--border .el-table__cell:first-child .cell {
     overflow: hidden;
     white-space: nowrap;
     text-overflow: ellipsis;
    }
    /deep/.el-table .cell, .el-table--border .el-table__cell:first-child .cell .el-tooltip {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    /deep/ .el-table thead {
      color: #515A6E;
    }
  }
  /deep/ .ivu-page {
    float: right;
    margin-right: 143px;
  }
}

</style>
