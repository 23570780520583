import {get,post} from "@/utils/axios"
// 查询工单
const getOptOut = p => get('/api-seller/apiConsoleGoods/getOptOut', p);
// 新建工单
const addWork = p => post('/api-seller/apiconsole/workOrder/addWork', p);

const selectWorkOrderReply = p => get('/api-seller/apiconsole/workOrder/selectWorkOrderReply', p);

const replyWork = p => post('/api-seller/apiconsole/workOrder/replyWork', p);

const closeWorkOrder = p => get('/api-seller/apiconsole/workOrder/closeWorkOrder', p);

export default {
    getOptOut,
    addWork,
    selectWorkOrderReply,
    replyWork,
    closeWorkOrder
}
